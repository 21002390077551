<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-card title="SaveSet Job Details">
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <dl class="row">
                <dt class="col-md-4">
                  Job Id
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.jobId }}
                </dd>
                <dt class="col-md-4">
                  Job name
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.jobName }}
                </dd>
                <dt class="col-md-4">
                  Group Tree
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.groupTree }}
                </dd>
                <dt class="col-md-4">
                  Level
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.level }}
                </dd>

                <dt class="col-md-4">
                  Status
                </dt>
                <dd class="col-md-8">
                  <JobStatusIcon :status="saveSet.calculatedStatus" />
                </dd>

                <dt class="col-md-4">
                  Exit code
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.exitCode }}
                </dd>

                <dt class="col-md-4">
                  Status
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.status }}
                </dd>

                <dt class="col-md-4">
                  Job state
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.jobState }}
                </dd>

                <template v-if="saveSet.parserState">
                  <dt class="col-md-4">
                    Parser state
                  </dt>
                  <dd class="col-md-8">
                    {{ saveSet.parserState }}
                  </dd>
                </template>

                <template v-if="saveSet.parserNote">
                  <dt class="col-md-4">
                    Parser note
                  </dt>
                  <dd class="col-md-8">
                    {{ saveSet.parserNote }}
                  </dd>
                </template>

                <hr>

                <template v-if="saveSet.size">
                  <dt class="col-md-4">
                    Size
                  </dt>
                  <dd class="col-md-8">
                    {{ saveSet.size | prettyBytes }}
                  </dd>
                </template>

                <template v-if="saveSet.files">
                  <dt class="col-md-4">
                    Files
                  </dt>
                  <dd class="col-md-8">
                    {{ saveSet.files }}
                  </dd>
                </template>
              </dl>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <dl class="row">
                <dt class="col-md-4">
                  Started on
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.start | formatDateTimeTz(asup.timezone) }}
                </dd>

                <dt class="col-md-4">
                  Ended on
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.end | formatDateTimeTz(asup.timezone) }}
                </dd>

                <dt class="col-md-4">
                  Duration
                </dt>
                <dd class="col-md-8">
                  {{ saveSet.duration | formatClrTimeSpan }}
                </dd>
              </dl>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card title="Related data">
          <dl class="row">
            <dt class="col-sm-4">
              Workflow Group
            </dt>
            <dd class="col-sm-8">
              {{ wfJob.group }}
            </dd>

            <dt class="col-sm-4">
              Workflow Policy
            </dt>
            <dd class="col-sm-8">
              {{ wfJob.policy }}
            </dd>

            <dt class="col-sm-4">
              Workflow
            </dt>
            <dd class="col-sm-8">
              <b-link
                :to="{
                  name: 'networker-detail.workflow-detail',
                  params: { workflowName: wfJob.workflow },
                  query: { group: wfJob.group, policy: wfJob.policy },
                }"
              >
                {{ wfJob.workflow }}
              </b-link>
            </dd>
            <dt class="col-sm-4">
              Workflow Job
            </dt>
            <dd class="col-sm-8">
              <JobStatusIcon
                :status="wfJob.calculatedJobStatus"
                :text="wfJob.jobId"
                :to="{ name: 'networker-detail.workflow-job-detail' }"
              />
            </dd>

            <dt class="col-sm-4">
              Client
            </dt>
            <dd class="col-sm-8">
              <JobStatusIcon
                :status="client.calculatedStatus"
                :text="client.clientName"
                :to="{
                  name: 'networker-detail.client-detail',
                  params: { clientName: client.clientName },
                }"
              />
            </dd>
          </dl>
        </b-card>
      </b-col>
    </b-row>

    <b-card title="SaveSet Job Log">
      <b-form-textarea
        :value="saveSet.jobLog"
        rows="18"
        readonly
      />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BCard,
  VBPopover,
  BFormTextarea,
  BLink,
} from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import NetWorkerService from '@/service/networker.service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    JobStatusIcon,
    BFormTextarea,
    BLink,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wfJob: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    wfJobId() {
      return this.$route.params.wfJobId
    },
    clientName() {
      return this.$route.params.client
    },
    saveSetJobId() {
      return this.$route.params.saveSetJobId
    },
    client() {
      if (!this.wfJob.clients) {
        return {}
      }

      return this.wfJob.clients.find(x => x.clientName === this.clientName)
    },
    saveSet() {
      const { client } = this

      if (client && client.saveSetJobs) {
        return client.saveSetJobs.find(x => x.jobId === this.saveSetJobId)
      }

      return {}
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      NetWorkerService.getWorkflowJobAsync(this.asup.id, this.wfJobId, {
        disableTenantFilter: true,
      })
        .then(result => {
          this.wfJob = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
